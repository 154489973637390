<template>
    <v-card>

      <!-- Headers -->
        <v-card-title>
            <v-row>
                <v-col
                cols="12"
                lg="6"
                md="6"
                sm="12"
                xs="12"
                >
                <span class="text--white text-h4 font-weight-black">Login by Whatsapp</span>
                </v-col>
                <v-col
                class="text-end"
                cols="12"
                lg="6"
                md="6"
                sm="12"
                xs="12"
                >
                <v-btn
                    color="primary"
                    @click="dialogAddLink = true"
                >
                    <v-icon
                    color="white"
                    left
                    >
                    {{ icons.mdiLinkBoxVariantOutline }}
                    </v-icon>
                    <span class="white--text">Create Login link</span>
                </v-btn>
                </v-col>
            </v-row>
        </v-card-title>
        <v-card-subtitle>
            <span
                class="text-subtitle-1 font-weight-regular"
            >Create Whatsapp OTP Description</span>
        </v-card-subtitle>
        <v-data-table
        v-model="selectedLink"
        item-key="id"
        :headers="headers"
        :items="loginLinkList"
        :search="search"
        :items-per-page="10"
        :loading="loading"
        loading-text="Loading... Please wait"
        return-object
        class="table-broadcast"
        :footer-props="{
          'items-per-page-text': $t('rowsPerPageLabel'),
          'items-per-page-options': [5, 10, 30, 50, 100],
        }"
        checkbox-color="#111B21"
      >
        <template v-slot:header.data-table-select="{ on, props }">
          <div class="d-flex mx-auto">
            <v-simple-checkbox
              :ripple="false"
              color="#111B21"
              v-bind="props"
              v-on="on"
            ></v-simple-checkbox>
          </div>
        </template>
        <template #[`item.name`]="{ item }">
          <span>{{ item.login_link_name }}</span>
        </template>
        <template #[`item.channel`]="{ item }">
          <span>{{ JSON.parse(item.instance).label }}</span>
        </template>
        <template #[`item.key`]="{ item }">
          <v-row
          justify="center"
          align="center"
          >
            <v-col
            cols="10"
            md="8"
            sm="8"
            >
              <v-hover v-slot="{ hover }">
                <v-btn
                block
                small
                class="no-uppercase px-1 py-1"
                style="position: relative"
                ripple="false"
                elevation="0"
                @click="copyJwtKey(item.jwt_key)"
                >
                  {{ item.jwt_key }}
                    <v-icon
                    small
                    v-if="hover"
                    style="position: absolute; left: -12px; top: -15px"
                    >
                      {{ icons.mdiContentCopy }}
                    </v-icon>
                </v-btn>
              </v-hover>
            </v-col>
            <v-col
            cols="2"
            md="4"
            sm="4"
            class="pa-0"
            >
              <v-btn 
              small
              icon
              v-if="item.loginLinkId !== selectedItemId"
              color="primary"
              @click="generateNewKey(item.loginLinkId)">
              <v-icon small>
                  {{ icons.mdiAutorenew  }}
                </v-icon>
              </v-btn>
              <v-btn
              small
              icon
              v-if="item.loginLinkId === selectedItemId"
              loading
              color="primary"
              >
                <v-icon small>
                  {{ icons.mdiAutorenew  }}
                </v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </template>
        <template #[`item.redirect_url`]="{ item }">
          <span>{{ item.redirect_url }}</span>
        </template>
        <template #[`item.action`]="{ item }">
          <v-container
          id="action-button-container"
          >
          <!-- top-left button -->
            <v-btn
            dark
            small
            outlined
            color="primary"
            class="my-1 mx-2 pa-1"
            @click="copyLink(item.loginLinkId)"
            >
              <v-icon
              small
              class="mr-2"
              >
                {{ icons.mdiVectorLink  }}
              </v-icon>
              Copy URL
            </v-btn>

            <!-- top-right button -->
            <v-btn
            dark
            fab
            icon
            x-small
            color="error"
            class="my-1"
            @click="openDeleteDialog(item.loginLinkId)"
            >
              <v-icon>
                {{ icons.mdiDelete }}
              </v-icon>
            </v-btn>
          </v-container>
          <v-container
          id="action-button-container"
          >
          <!-- bottom-left button -->
            <v-btn
            dark
            small
            outlined
            color="primary"
            class="my-1 mx-2 pa-1"
            @click="openLink(item.loginLinkId)"
            >
              <v-snackbar
              v-model="copyAction"
              color="success"
              :timeout="1000"
              :bottom="true"
              :right="true"
              width="20px"
              app
              >
                Copied !
              </v-snackbar>
              <v-icon
              small
              class="mr-2"
              >
                {{ icons.mdiOpenInNew }}
              </v-icon>
              Open URL
            </v-btn>
            <!-- bottom-right button -->
            <v-btn
            dark
            fab
            icon
            x-small
            color="info"
            class="my-1"
            @click="openUpdateDialog(item)"
            >
              <v-icon>
                {{ icons.mdiPencil }}
              </v-icon>
            </v-btn>
          </v-container>
        </template>
        </v-data-table>
      
      <!-- Create Login Link Dialog -->
      <v-dialog
      v-model="dialogAddLink"
      width="800px"
      persistent
      >
        <v-card>
            <v-card-title>
                <span class="text--white text-h5 font-weight-black">Add a new Whatsapp login link</span>
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text>
                <v-row class="mb-n8">
                    <v-col
                    cols="12"
                    md="3"
                    sm="4"
                    class="flex"
                    >
                        <p class="order-text me-3 mt-2">
                        Name * <span class="mobile-col-show ms-3"> : </span>
                        </p>
                    </v-col>
                    <v-col
                    cols="12"
                    md="7"
                    sm="8"
                    class="d-flex col-margin-top-mobile"
                    >
                        <v-text-field
                        v-model="createLoginLinkData.name"
                        outlined
                        hide-details
                        required
                        class="mb-2"
                        dense
                        ></v-text-field>
                    </v-col>
                </v-row>
                <v-row class="mb-n8">
                    <v-col
                    cols="12"
                    md="3"
                    sm="4"
                    class="flex"
                    >
                        <p class="order-text me-3 mt-2">
                        Channel * <span class="mobile-col-show ms-3"> : </span>
                        </p>
                    </v-col>
                    <v-col
                    cols="12"
                    md="7"
                    sm="8"
                    class="d-flex col-margin-top-mobile"
                    >
                      <v-select
                      outlined
                      v-model="createLoginLinkData.channel"
                      :items="instances"
                      item-text="label"
                      return-object
                      outlined
                      :label="$t('channels.channel')"
                      hide-details
                      required
                      class="mb-3"
                      dense
                      attach
                      >

                      </v-select>
                    </v-col>
                </v-row>
                <v-row class="mb-n8">
                    <v-col
                    cols="12"
                    md="3"
                    sm="4"
                    class="flex"
                    >
                        <p class="order-text me-3 mt-2">
                        JWT Key * <span class="mobile-col-show ms-3"> : </span>
                        </p>
                    </v-col>
                    <v-col
                    cols="12"
                    md="7"
                    sm="8"
                    class="d-flex col-margin-top-mobile"
                    >
                        <v-text-field
                        v-model="createLoginLinkData.jwtKey"
                        outlined
                        hide-details
                        required
                        class="mb-2"
                        dense
                        ></v-text-field>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col
                    cols="12"
                    md="3"
                    sm="4"
                    class="flex"
                    >
                        <p class="order-text me-3 mt-2">
                        Redirect URL * <span class="mobile-col-show ms-3"> : </span>
                        </p>
                    </v-col>
                    <v-col
                    cols="12"
                    md="7"
                    sm="8"
                    class="d-flex col-margin-top-mobile"
                    >
                        <v-text-field
                        v-model="createLoginLinkData.redirect_url"
                        outlined
                        hide-details
                        required
                        class="mb-2"
                        dense
                        ></v-text-field>
                    </v-col>
                </v-row>
            </v-card-text>
            
            <!-- bottom dialog buttons -->
            <v-card-actions>
              <v-spacer></v-spacer>

              <v-btn
                color="#ffeeee"
                @click="dialogAddLink = false"
              >
                <span class="error--text">{{ $t('cancel') }}</span>
              </v-btn>

              <v-btn
                color="success"
                @click="submitCreateLinkForm"
              >
                {{ $t('EOrdering.save') }}
              </v-btn>
          </v-card-actions>
          
          <!-- error snackbar -->
          <v-snackbar
          v-model="showError"
          color="error"
          :timeout="2000"
          :top="true"
          style="z-index: 5 !important"
          >
          {{ errorMsg }}
          <template v-slot:action="{ attrs }">
            <v-btn
              color="red"
              text
              v-bind="attrs"
              @click="showError = false"
            >
              Close
            </v-btn>
          </template>
        </v-snackbar>
        </v-card>
      </v-dialog>
      
      <!-- Delete Login Link Dialog -->
      <v-dialog
      v-model="showDeleteDialog"
      persistent
      width="auto"
      >
        <v-card
        id="delete-dialog-card"
        >
          <p>Are you sure want to delete this login link?</p>
          <v-container
          fluid
          >
            <v-btn
              color="success"
              @click="showDeleteDialog = false"
            >
              <span class="white--text">{{ $t('cancel') }}</span>
            </v-btn>

            <v-btn
              color="#ffeeee"
              class="error--text"
              @click="deleteLoginLink"
            >
              {{ $t('delete') }}
            </v-btn>
          </v-container>
        </v-card>
      </v-dialog>

      <!-- Update Login Link Dialog -->
      <v-dialog
      v-model="showUpdateDialog"
      width="800px"
      persistent
      >
        <v-card>
            <v-card-title>
                <span class="text--white text-h5 font-weight-black">Edit your Whatsapp login link</span>
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text>
                <v-row class="mb-n8">
                    <v-col
                    cols="12"
                    md="3"
                    sm="4"
                    class="flex"
                    >
                        <p class="order-text me-3 mt-2">
                        Name * <span class="mobile-col-show ms-3"> : </span>
                        </p>
                    </v-col>
                    <v-col
                    cols="12"
                    md="7"
                    sm="8"
                    class="d-flex col-margin-top-mobile"
                    >
                        <v-text-field
                        v-model="selectedLoginLink.login_link_name"
                        outlined
                        hide-details
                        required
                        class="mb-2"
                        dense
                        ></v-text-field>
                    </v-col>
                </v-row>
                <v-row class="mb-n8">
                    <v-col
                    cols="12"
                    md="3"
                    sm="4"
                    class="flex"
                    >
                        <p class="order-text me-3 mt-2">
                        Channel * <span class="mobile-col-show ms-3"> : </span>
                        </p>
                    </v-col>
                    <v-col
                    cols="12"
                    md="7"
                    sm="8"
                    class="d-flex col-margin-top-mobile"
                    >
                        <v-select
                        outlined
                        v-model="selectedLoginLink.instance"
                        :items="instances"
                        item-text="label"
                        return-object
                        outlined
                        :label="$t('channels.channel')"
                        hide-details
                        required
                        class="mb-3"
                        dense
                        attach
                        >

                        </v-select>
                    </v-col>
                </v-row>
                <v-row class="mb-n8">
                    <v-col
                    cols="12"
                    md="3"
                    sm="4"
                    class="flex"
                    >
                        <p class="order-text me-3 mt-2">
                        JWT Key * <span class="mobile-col-show ms-3"> : </span>
                        </p>
                    </v-col>
                    <v-col
                    cols="12"
                    md="7"
                    sm="8"
                    class="d-flex col-margin-top-mobile"
                    >
                        <v-text-field
                        v-model="selectedLoginLink.jwt_key"
                        outlined
                        hide-details
                        required
                        class="mb-2"
                        dense
                        ></v-text-field>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col
                    cols="12"
                    md="3"
                    sm="4"
                    class="flex"
                    >
                        <p class="order-text me-3 mt-2">
                        Redirect URL * <span class="mobile-col-show ms-3"> : </span>
                        </p>
                    </v-col>
                    <v-col
                    cols="12"
                    md="7"
                    sm="8"
                    class="d-flex col-margin-top-mobile"
                    >
                        <v-text-field
                        v-model="selectedLoginLink.redirect_url"
                        outlined
                        hide-details
                        required
                        class="mb-2"
                        dense
                        ></v-text-field>
                    </v-col>
                </v-row>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>

              <v-btn
                color="#ffeeee"
                @click="showUpdateDialog = false"
              >
                <span class="error--text">{{ $t('cancel') }}</span>
              </v-btn>

              <v-btn
                color="success"
                @click="updateLoginLink"
              >
                {{ $t('EOrdering.save') }}
              </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <!-- error snackbar -->
      <v-snackbar
          v-model="showError"
          color="error"
          :timeout="2000"
          :top="true"
          style="z-index: 5 !important"
          >
          {{ errorMsg }}
          <template v-slot:action="{ attrs }">
            <v-btn
              color="red"
              text
              v-bind="attrs"
              @click="showError = false"
            >
              Close
            </v-btn>
          </template>
      </v-snackbar>
    </v-card>
</template>

<script>
import {
mdiAutorenew, mdiContentCopy, mdiDelete, mdiLinkBoxVariantOutline, mdiOpenInNew, mdiPencil,
mdiVectorLink
} from '@mdi/js'
export default {
  data() {
    return {
      icons: {
        mdiLinkBoxVariantOutline,
        mdiDelete,
        mdiPencil,
        mdiVectorLink,
        mdiOpenInNew,
        mdiContentCopy,
        mdiAutorenew,
      },
      loading: false,
      links: [],
      search: '',
      selectedLink: null,
      dialogAddLink: false,
      selectedInstance: '',
      createLoginLinkData: {
        name: '',
        channel: '',
        jwtKey: '',
        redirect_url: '',
      },
      loginLinkList: [],
      showError: false,
      errorMsg: '',
      showDeleteDialog: false,
      showUpdateDialog: false,
      selectedLoginLink: {},
      copyAction: false,
      loadingGenerateKey: false,
      selectedItemId: '',
    }
  },
  computed: {
    headers() {
      return [
        {
          text: 'Name',
          value: 'name',
          align: 'center',
        },
        {
          text: 'Channel',
          value: 'channel',
          align: 'center',
        },
        {
          text: 'Key',
          value: 'key',
          align: 'center',
        },
        {
          text: 'Redirect URL',
          value: 'redirect_url',
          align: 'center',
        },
        {
          text: '',
          value: 'action',
          align: 'center',
        },
      ]
    },
    instances() {
      const listInstance = []
      if (this.$store.getters['auth/getListInstance'].length !== 0) {
        this.$store.getters['auth/getListInstance'].value.forEach(instance => {
          listInstance.push({
            label: `${
              instance.label.startsWith('-') || instance.label.includes('Default') || instance.label.includes('62')
                ? instance.label_server
                : instance.label
            } - (${instance.phone_number})`,
            value: instance.phone_number,
            instance: instance._id,
          })
        })
      }
      //console.log(listInstance)

      return listInstance
    },
    user() {
      return this.$store.getters['auth/getUser']
    },
  },
  mounted() {
    this.getLoginLinkList()
  },
  methods: {
    async submitCreateLinkForm() {
      //console.log(this.createLoginLinkData)
      const validUrl = await this.isValidUrl(this.createLoginLinkData.redirect_url)
      if (validUrl) {
        const res = await this.$store.dispatch('link/saveLoginLink', {
          ...this.createLoginLinkData,
          sub_id: this.user.sub_id,
        })
        // //console.log('res :', res)
        if (res.status) {
          this.createLoginLinkData.name = ''
          this.createLoginLinkData.channel = ''
          this.createLoginLinkData.jwtKey = ''
          this.createLoginLinkData.redirect_url = ''

          // update the login link list
          await this.getLoginLinkList()
          this.dialogAddLink = false
        } else {
          this.errorMsg = res.message
          this.showError = true
        }
      } else {
        this.errorMsg = 'Invalid Url'
        this.showError = true
      }
    },
    async getLoginLinkList() {
      const response = await this.$store.dispatch('link/fetchLoginLinkList', {
        sub_id: this.user.sub_id,
      })
      if (response.status) {
        this.loginLinkList = response.data
      } else {
        this.errorMsg = res.message
        this.showError = true
      }
    },
    async openDeleteDialog(loginLinkId) {
      this.selectedloginLinkId = loginLinkId
      this.showDeleteDialog = true
    },
    async deleteLoginLink() {
      const res = await this.$store.dispatch('link/deleteLoginLink', {
        loginLinkId: this.selectedloginLinkId,
        sub_id: this.user.sub_id,
      })
      // //console.log('res in delete function :', res)
      if (!res.status) {
        this.showError = true
        this.errorMsg = res.message
      } else {
        await this.getLoginLinkList()
        this.showDeleteDialog = false
      }
    },
    async openUpdateDialog(item) {
      const newItem = {
        ...item,
        instance: JSON.parse(item.instance),
      }
      this.selectedLoginLink = newItem
      this.showUpdateDialog = true
    },
    async updateLoginLink() {
      const validUrl = await this.isValidUrl(this.selectedLoginLink.redirect_url)
      if (validUrl) {
        const res = await this.$store.dispatch('link/updateLoginLink', {
          ...this.selectedLoginLink,
          sub_id: this.user.sub_id,
        })
        // //console.log('res :', res)
        if (res.status) {
          this.selectedLoginLink = {}

          // update the login link list
          await this.getLoginLinkList()
          this.showUpdateDialog = false
        } else {
          this.errorMsg = res.message
          this.showError = true
        }
      } else {
        this.errorMsg = 'Invalid Url'
        this.showError = true
      }
    },
    async isValidUrl(url) {
      try {
        new URL(url)
      } catch (e) {
        console.error(e)
        return false
      }
      return true
    },
    async copyLink(loginLinkId) {
      const routeData = this.$router.resolve({ name: 'login-by-wa', params: { loginLinkId } })
      const url = window.location.origin + routeData.resolved.fullPath
      navigator.clipboard.writeText(url)
      this.copyAction = true
    },
    async openLink(loginLinkId) {
      const routeData = this.$router.resolve({ name: 'login-by-wa', params: { loginLinkId } })
      window.open(routeData.href, '_blank')
    },
    async generateJwtKey(length) {
      var result = ''
      var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
      var charactersLength = characters.length
      for (var i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength))
      }
      return result
    },
    async generateNewKey(loginLinkId) {
      const selectedItem = this.loginLinkList.find(el => el.loginLinkId === loginLinkId)
      this.selectedItemId = selectedItem.loginLinkId
      this.loadingGenerateKey = true

      const jwt_key = await this.generateJwtKey(20)
      const res = await this.$store.dispatch('link/updateJwtKey', {
        jwt_key,
        loginLinkId,
        sub_id: this.user.sub_id,
      })
      if (res.status) {
        // await this.getLoginLinkList()
        await this.updateLoginLinkData(res.data)
        this.selectedItemId = ''
        this.loadingGenerateKey = false
      } else {
        this.showError = true
        this.error = res.message
      }
    },
    async updateLoginLinkData(newItem) {
      const oldItemIndex = this.loginLinkList.findIndex(el => el.loginLinkId === newItem.loginLinkId)
      const updatedList = [...this.loginLinkList]
      updatedList[oldItemIndex] = newItem
      this.loginLinkList = updatedList
    },
    async copyJwtKey(key) {
      navigator.clipboard.writeText(key)
      this.copyAction = true
    },
  },
}
</script>

<style>
#action-button-container {
  padding: 0px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
#delete-dialog-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  justify-content: center;
}
#delete-dialog-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  justify-content: center;
  padding: 12px;
}
.no-uppercase {
  text-transform: unset !important;
}
</style>